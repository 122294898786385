import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import CampaignIcon from "@mui/icons-material/Campaign";
import CloseIcon from "@mui/icons-material/Close";
import AnimatedPage from "../components/AnimatedPage";
import swal from "sweetalert";
import { useForm } from "react-hook-form";

function Home() {
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    const swiperMenu = {
        autoplay: false,
        slidesPerView: 5.3,
        centerInsufficientSlides: true,
        spaceBetween: 0,
        grabCursor: true
    };
    const swiperGame = {
        autoplay: true,
        slidesPerView: 3.6,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame1 = {
        autoplay: true,
        slidesPerView: 3.2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame2 = {
        autoplay: false,
        slidesPerView: 2.2,
        centerInsufficientSlides: false,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame3 = {
        autoplay: false,
        slidesPerView: 1.1,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const searchParams = new URLSearchParams(window.location.search);
    const notifyParam = searchParams.get("notify");
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [bet, setBet] = useState(JSON.parse(localStorage.getItem("xsmb")));
    const [game, setGame] = useState(null);
    const [title, setTitle] = useState(null);
    const [isShow, setShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [playGame, setPlayGame] = useState(null);
    const [product, setProduct] = useState(null);
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();
    const getProfile = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    };
    useEffect(() => {
        getProfile();
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
        setTimeout(() => {
            axios.get(`https://mu88.live/api/front/open/lottery/history/list/1/miba`).then((res) => {
                setBet(res.data.t);
                if (bet != res.data.t) {
                    setBet(res.data.t);
                    localStorage.setItem("xsmb", JSON.stringify(res.data.t));
                }
            });
        }, 5000);
    }, []);
    useEffect(() => {
        if (profile) {
            axios.post(`${process.env.REACT_APP_API_URL}/casino/create`);
        }
    }, [profile]);
    const [activeOption, setActiveOption] = useState("0");
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const date0 = new Date();
    const currentHour = date0.getHours();
    const [dateMT, setDateMT] = useState(date0.getDay());
    const [dataMT, setDataMT] = useState();
    const [imageMT, setImageMT] = useState([]);
    const [dateMN, setDateMN] = useState(date0.getDay());
    const [dataMN, setDataMN] = useState([]);
    const [imageMN, setImageMN] = useState([]);
    useEffect(() => {
        if (dateMT == 0) {
            if (currentHour < 18) {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            } else {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            }
        }
        if (dateMT == 1) {
            if (currentHour < 18) {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            } else {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            }
        }
        if (dateMT == 2) {
            if (currentHour < 18) {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            } else {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            }
        }
        if (dateMT == 3) {
            if (currentHour < 18) {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            } else {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            }
        }
        if (dateMT == 4) {
            if (currentHour < 18) {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            } else {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            }
        }
        if (dateMT == 5) {
            if (currentHour < 18) {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            } else {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            }
        }
        if (dateMT == 6) {
            if (currentHour < 18) {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            } else {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            }
        }
    }, [dateMT]);
    useEffect(() => {
        if (dateMN == 0) {
            if (currentHour < 17) {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            } else {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            }
        }
        if (dateMN == 1) {
            if (currentHour < 17) {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            } else {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            }
        }
        if (dateMN == 2) {
            if (currentHour < 17) {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            } else {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            }
        }
        if (dateMN == 3) {
            if (currentHour < 17) {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            } else {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            }
        }
        if (dateMN == 4) {
            if (currentHour < 17) {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            } else {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            }
        }
        if (dateMN == 5) {
            if (currentHour < 17) {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            } else {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            }
        }
        if (dateMN == 6) {
            if (currentHour < 17) {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            } else {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            }
        }
    }, [dateMN]);

    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    function launchGame(params) {
        if (profile === null) {
            navigate("/login");
            return;
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            platform: isMobileDevice == true ? "html5" : "html5-desktop"
        };
        setLoading(true);
        let a;
        switch (params.product_type) {
            case "4":
                a = "AG";
                break;
            case "79":
                a = "BBIN";
                break;
            case "104":
                a = "CMD";
                break;
            case "16":
                a = "CQ9";
                break;
            case "27":
                a = "DG";
                break;
            case "86":
                a = "Evolution";
                break;
            case "142":
                a = "FB";
                break;
            case "140":
                a = "JILI";
                break;
            case "121":
                a = "KingMaker";
                break;
            case "102":
                a = "V8 Poker";
                break;
            case "123":
                a = "King Poker";
                break;
            case "43":
                a = "MG";
                break;
            case "39":
                a = "PP";
                break;
            case "174":
                a = "SABA";
                break;
            case "118":
                a = "WM";
                break;
            case "112":
                a = "SEXY";
                break;
            case "54":
                a = "SBO";
                break;
            case "151":
                a = "UG";
                break;
            case "99":
                a = "TF";
                break;
            case "3":
                a = "Playtech";
                break;
            case "28":
                a = "AllBet";
                break;
            case "55":
                a = "JDB";
                break;
            case "202":
                a = "WS168";
                break;
            case "61":
                a = "IMES";
                break;
            case "132":
                a = "SV388";
                break;
            default:
                a = "";
                break;
        }
        setSanh(a);
        setProduct(params.product_type);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/launchgame`, form)
            .then((res) => {
                if (res.data.data.status == 0) {
                    setPlayGame(res.data.data.game_url);
                    axios
                        .post(`${process.env.REACT_APP_API_URL}/casino/getbalance`, { product_type: params.product_type })
                        .then((res) => {
                            setBalance(res.data.data.balance);
                            setLoading(false);
                        })
                        .catch((err) => swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error"));
                } else {
                    setLoading(false);
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                //swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    const menuGame = (title, product_type, game_type) => {
        setTitle(title);
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/getgamelist`, { product_type, game_type })
            .then((res) => {
                if (res.data.data.length == 0) {
                    swal("Thông báo", "Sảnh game bảo trì", "error");
                    setLoading(false);
                } else {
                    setGame(res.data.data[0]);
                    setLoading(false);
                    setShow(true);
                }
            })
            .catch((err) => {
                swal("Thông báo", "Sảnh game bảo trì", "error");
                setLoading(false);
            });
    };
    const [newMoney, setNewMoney] = useState(null);
    const [balance, setBalance] = useState(0);
    const [sanh, setSanh] = useState(null);
    const [type, setType] = useState(null);
    const [load, setLoad] = useState(false);
    const onSubmit = (data) => {
        if (load === false) {
            if (!sanh) {
                swal("Thông báo", "Vui lòng chọn sảnh", "warning");
                return;
            }
            const formData = {
                product_type: product,
                type: type,
                money: Number(data.money.replaceAll(".", "").replaceAll(",", ""))
            };
            if (!type) {
                swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
                return;
            }
            setLoad(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/casino/transfer`, formData)
                .then((res) => {
                    if (type == "2") {
                        swal("Thành công", `Chuyển quỹ ra khỏi sảnh thành công!`, "success").then(() => window.location.reload());
                    } else {
                        swal("Thành công", `Chuyển quỹ vào sảnh thành công!`, "success").then(() => (window.location.href = playGame));
                    }
                    axios
                        .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                        .then((res) => {
                            setProfile(res.data.data);
                        })
                        .catch((err) => localStorage.removeItem("user"));
                    setLoad(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoad(false);
                });
        }
    };
    const selectAll = (e) => {
        if (type == 1) {
            setNewMoney(profile.money > 0 && Math.floor(profile.money).toLocaleString("vi-VN"));
        } else if (type == 2) {
            balance > 0 && setNewMoney(Math.floor(balance).toLocaleString("vi-VN"));
        } else {
            swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
        }
    };
    const openGame = () => {
        window.location.href = playGame;
    };
    const [yoGame, setYoGame] = useState(false);
    const [yoPopup, setYoPopup] = useState(false);
    const [yoType, setYoType] = useState(1);
    const [yoMoney, setYoMoney] = useState(0);
    const [yoBalance, setYoBalance] = useState(0);
    const openYoGame = () => {
        if (profile === null) {
            return navigate("/login");
        }
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/logingame`)
            .then((res) => {
                setYoGame(res.data.data);
                setYoBalance(res.data.data?.AccountInfo?.Balance);
                setYoPopup(true);
                setLoading(false);
            })
            .catch((err) => {
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                setLoading(false);
            });
    };
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitYoGame = async () => {
        if (profile === null) {
            return navigate("/login");
        }
        if (isSubmitting) {
            return swal("Thông báo", "Bạn thao tác quá nhanh", "warning");
        }
        setLoading(true);
        const transactionAmount = Number(yoMoney?.replaceAll(".", "")?.replaceAll(",", ""));
        if (transactionAmount < 0) {
            setLoading(false);
            return swal("Chú ý", "Vui lòng nhập số tiền cần giao dịch", "warning");
        }
        const formData = {
            amount: transactionAmount,
            type: yoType
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/transfergame`, formData)
            .then((res) => {
                axios
                    .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                    .then((res) => {
                        setProfile(res.data.data);
                        localStorage.setItem("profile", JSON.stringify(res.data.data));
                    })
                    .catch((err) => localStorage.removeItem("user"));
                swal(yoType == 1 ? "Nạp tiền thành công" : "Rút tiền thành công", "", "success");
                setYoBalance(res.data.data.new_balance);
                setTimeout(() => {
                    setIsSubmitting(false);
                }, 3000);
                setLoading(false);
            })
            .catch((err) => {
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                setLoading(false);
                setIsSubmitting(false);
            });
    };
    function handleImageError(e, productCode, tcgGameCode) {
        e.target.onerror = null;
        e.target.src = `https://images.21947392.com/TCG_GAME_ICONS/${productCode}/${tcgGameCode}.png`;
    }
    return (
        <>
            {isLoading ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <Header profile={profile} />
            {swiperParams && notify && (
                <AnimatedPage>
                    <div className="box-slide" style={{ overflow: "hidden" }}>
                        <Swiper {...swiperParams}>
                            {notify.map((item, index) => (
                                <>
                                    {index > 1 && item.title !== "marquee" && item.title !== "popup" && item.isShow === true ? (
                                        <SwiperSlide>
                                            <img alt={item.title.replace("}", "").replace("{", "")} src={item.image} />
                                        </SwiperSlide>
                                    ) : null}
                                </>
                            ))}
                        </Swiper>
                        {notify ? (
                            <>
                                {notify[0].isShow === true && notify[0].title === "marquee" ? (
                                    <div className="marquees">
                                        <div>
                                            <i class="mps-news"></i>
                                        </div>
                                        <div>
                                            <div
                                                className="chaychu"
                                                style={{ animation: "chuchay " + notify[0].content.length / 10 + "s linear infinite" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: notify[0].content
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                    <div className="wallet-item">
                        <div class="wallet-amount">
                            <b>{profile ? Math.floor(profile?.money).toLocaleString("vi-VN") : ""}</b>
                        </div>
                        <div class="wallet-func">
                            <div class="wallet-func-item partner">
                                <a>
                                    <img src={require("../../statics/func-vip.png")} />
                                    <h3>VIP</h3>
                                </a>
                            </div>
                            <div class="wallet-func-item mobile">
                                <a href="https://app-download.alltocon.com/AE888/" target="_blank" rel="noreferrer">
                                    <img src={require("../../statics/icon_mobile_download.png")} />
                                    <h3>Tải APP</h3>
                                </a>
                            </div>
                            <div class="wallet-func-item deposit">
                                <Link to="/recharge">
                                    <img src={require("../../statics/icon_wallet_deposit.png")} />
                                    <h3>Nạp tiền</h3>
                                </Link>
                            </div>
                            <div class="wallet-func-item withdrawal">
                                <Link to="/withdraw">
                                    <img src={require("../../statics/icon_wallet_withdraw.png")} />
                                    <h3>Rút tiền</h3>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="content-game-home">
                        <div className="content-game-left">
                            <div className="menu-game-home">
                                <div className={`menu-game-item ${activeOption === "0" ? "active" : ""}`} onClick={() => handleOptionClick("0")}>
                                    <img src={require("../../statics/navicon-hot.png")} />
                                    <h3>Hot Games</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "1" ? "active" : ""}`} onClick={() => handleOptionClick("1")}>
                                    <img src={require("../../statics/navicon-sports.png")} />
                                    <h3>Thể Thao</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "2" ? "active" : ""}`} onClick={() => handleOptionClick("2")}>
                                    <img src={require("../../statics/navicon-live.png")} />
                                    <h3>Sòng Bài</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "3" ? "active" : ""}`} onClick={() => handleOptionClick("3")}>
                                    <img src={require("../../statics/navicon-cock.png")} />
                                    <h3>Đá Gà</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "4" ? "active" : ""}`} onClick={() => handleOptionClick("4")}>
                                    <img src={require("../../statics/navicon-egame.png")} />
                                    <h3>Slots</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "5" ? "active" : ""}`} onClick={() => handleOptionClick("5")}>
                                    <img src={require("../../statics/navicon-chess.png")} />
                                    <h3>Game Bài</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "6" ? "active" : ""}`} onClick={() => handleOptionClick("6")}>
                                    <img src={require("../../statics/navicon-mpg.png")} />
                                    <h3>Bắn Cá</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "7" ? "active" : ""}`} onClick={() => handleOptionClick("7")}>
                                    <img src={require("../../statics/navicon-lotto.png")} />
                                    <h3>Xổ Số</h3>
                                </div>
                                <div className={`menu-game-item ${activeOption === "8" ? "active" : ""}`} onClick={() => handleOptionClick("8")}>
                                    <img src={require("../../statics/navicon-esports.png")} />
                                    <h3>E-Sports</h3>
                                </div>
                            </div>
                        </div>
                        <div className="content-game-right">
                            {activeOption === "0" && (
                                <div className="api-game-flex">
                                    <div className="api-game" onClick={() => launchGame({ product_type: "132", game_code: "SV3002" })}>
                                        <img alt="" src={require("../../statics/game/ca7ae587-e513-4b65-aa87-1e373cb06ada.jpg")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "4", game_code: "A00070" })}>
                                        <img alt="" src={require("../../statics/game/9d894e1e-d207-41a1-9b8f-e96698449806.jpg")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "174", game_code: "SB0001" })}>
                                        <img alt="" src={require("../../statics/game/c4f96607-e6bb-4f1a-aaec-29604774bdc9.jpg")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "112", game_code: "SEX001" })}>
                                        <img alt="" src={require("../../statics/game/1b2e1a19-bf56-42bd-a9cc-6aa4ad2195f8.jpg")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "27", game_code: "DG0013" })}>
                                        <img alt="" src={require("../../statics/game/4663f848-826a-4ba4-a4ae-6e13a2717691.jpg")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ product_type: "142", game_code: "FB0001" })}>
                                        <img alt="" src={require("../../statics/game/9ad104ef-4449-4a20-adc5-b252a86b51b5.jpg")} />
                                    </div>
                                </div>
                            )}
                            {activeOption === "1" && (
                                <>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "174", game_code: "SB0001" })}>
                                        <img alt="" src={require("../../statics/game/sports-saba.png")} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/saba.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "54", game_code: "SBO041" })}>
                                        <img alt="" src={require("../../statics/game/sports-sbobet.png")} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/sbobet.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "104", game_code: "CMD001" })}>
                                        <img alt="" src={require("../../statics/game/sports-cmdbet.png")} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/cmdbet.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "151", game_code: "UG2001" })}>
                                        <img alt="" src={require("../../statics/game/sports-ugaming.png")} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/ugaming.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "142", game_code: "FB0001" })}>
                                        <img alt="" src={require("../../statics/game/sports-lucky_sports.png")} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/lucky_sports.png")} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeOption === "2" && (
                                <>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "4", game_code: "A00070" })}>
                                        <img alt="" src={require("../../statics/game/live-via_casino.png")} style={{ height: "150px" }} />
                                    </div>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "118", game_code: "WM0001" })}>
                                        <img alt="" src={require("../../statics/game/live_wm.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/wm.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "27", game_code: "DG0013" })}>
                                        <img alt="" src={require("../../statics/game/live_dg.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/dg.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "86", game_code: "EG0001" })}>
                                        <img alt="" src={require("../../statics/game/live_evo.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/evo.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "112", game_code: "SEX001" })}>
                                        <img alt="" src={require("../../statics/game/live_sexybcrt.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/sexybcrt.png")} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeOption === "3" && (
                                <>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "202", game_code: "WS1682" })}>
                                        <img alt="" src={require("../../statics/game/animal_ws.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/ws.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "132", game_code: "SV3002" })}>
                                        <img alt="" src={require("../../statics/game/animal_sv.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/sv.png")} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeOption === "4" && (
                                <>
                                    <div className="api-game bg" onClick={() => menuGame("Nổ hũ", "140", "RNG")}>
                                        <img alt="" src={require("../../statics/game/egame_jili.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/jili.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => menuGame("Nổ hũ", "55", "RNG")}>
                                        <img alt="" src={require("../../statics/game/egame_jdb.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/jdb.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => menuGame("Nổ hũ", "43", "RNG")}>
                                        <img alt="" src={require("../../statics/game/egame_mg.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/mg.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => menuGame("Nổ hũ", "3", "RNG")}>
                                        <img alt="" src={require("../../statics/game/egame_pt.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/pt.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => menuGame("Nổ hũ", "4", "RNG")}>
                                        <img alt="" src={require("../../statics/game/egame_ag.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/ag.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => menuGame("Nổ hũ", "79", "RNG")}>
                                        <img alt="" src={require("../../statics/game/egame_bbin.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/bbin.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => menuGame("Nổ hũ", "39", "RNG")}>
                                        <img alt="" src={require("../../statics/game/egame_pp.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/pp.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => menuGame("Nổ hũ", "16", "RNG")}>
                                        <img alt="" src={require("../../statics/game/egame_cq9.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/cq9.png")} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeOption === "5" && (
                                <>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "102", game_code: "LCC001" })}>
                                        <img alt="" src={require("../../statics/game/chess_lc.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/lc.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "121", game_code: "KM0001" })}>
                                        <img alt="" src={require("../../statics/game/chess_kingmaker.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/kingmaker.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "123", game_code: "KP0001" })}>
                                        <img alt="" src={require("../../statics/game/chess_card365.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/card365.png")} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeOption === "6" && (
                                <>
                                    <div className="api-game bg" onClick={() => menuGame("Bắn cá", "140", "FISH")}>
                                        <img alt="" src={require("../../statics/game/mpg_jili.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/jili.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => menuGame("Bắn cá", "55", "FISH")}>
                                        <img alt="" src={require("../../statics/game/mpg_jdb.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/jdb.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => menuGame("Bắn cá", "16", "FISH")}>
                                        <img alt="" src={require("../../statics/game/mpg_cq9.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/cq9.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => menuGame("Bắn cá", "43", "FISH")}>
                                        <img alt="" src={require("../../statics/game/mpg_mg.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/mg.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => menuGame("Bắn cá", "3", "FISH")}>
                                        <img alt="" src={require("../../statics/game/mpg_tp.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/pt.png")} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeOption === "7" && (
                                <div className="list-game">
                                    <div className="title" onClick={() => navigate("/xoso")}>
                                        Nổi bật >>
                                    </div>
                                    <div className="box-miba">
                                        <div className="box-miba-left" onClick={() => navigate("/xsmb/lo2")}>
                                            <img alt="" src={require("../../images/3mien/mienbac.png")} />
                                            <div className="dat-cuoc" onClick={() => navigate("/xsmb/lo2")}>
                                                Đặt cược
                                            </div>
                                        </div>
                                        <div className="box-miba-right">
                                            <div className="box-text">
                                                Miền Bắc <img alt="" src={require("../../img/icon-hot.png")} className="icon-hot" />
                                            </div>
                                            <div className="box-text">Ngày: {bet?.issueList[0]?.turnNum}</div>
                                            <div className="box-miba-kq">
                                                {bet?.issueList[0]?.openNum.split(",").map((x) => (
                                                    <div className="ball">
                                                        <img alt="" src={`/images/ball/${x}.png`} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="title">Xổ số nhanh</div>
                                    <div className="list-game-xs">
                                        <Link to="/xs75s/lo2">
                                            <img alt="" src={require(`../../images/mbmg.png`)} />
                                        </Link>
                                        <Link to="/xs120s/lo2">
                                            <img alt="" src={require(`../../images/st2p.png`)} />
                                        </Link>
                                    </div>
                                    <div className="title" onClick={() => navigate("/xoso")}>
                                        Miền Nam >>
                                    </div>
                                    <div className="list-game-xs">
                                        {dataMN?.map((item, index) => (
                                            <>
                                                <Link to={`/xsmn/lo2/${item}`} key={item}>
                                                    <img alt="" src={require(`../../images/3mien/${imageMN[index]}.png`)} />
                                                </Link>
                                            </>
                                        ))}
                                    </div>
                                    <div className="title" onClick={() => navigate("/xoso")}>
                                        Miền Trung >>
                                    </div>
                                    <div className="list-game-xs">
                                        {dataMT?.map((item, index) => (
                                            <>
                                                <Link to={`/xsmt/lo2/${item}`} key={item}>
                                                    <img alt="" src={require(`../../images/3mien/${imageMT[index]}.png`)} />
                                                </Link>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {activeOption === "8" && (
                                <>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "99", game_code: "TF0001" })}>
                                        <img alt="" src={require("../../statics/game/esports_tf.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/tf.png")} />
                                        </div>
                                    </div>
                                    <div className="api-game bg" onClick={() => launchGame({ product_type: "61", game_code: "IMES17" })}>
                                        <img alt="" src={require("../../statics/game/esports_im_esports.png")} style={{ height: "150px" }} />
                                        <div className="api-game-logo">
                                            <img alt="" src={require("../../statics/game/im_esports.png")} />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div style={{ padding: "0 0.32rem 2rem" }}>
                        <div className="content-game">
                            {/*activeOption !== "1" && (
                                <div className="baohanh">
                                    <img alt="" src={require("../../images/bao-hanh-va-sua-chua.png")} style={{ maxWidth: "8rem" }} />
                                    <div className="nangcap">Hệ thống đang nâng cấp...</div>
                                </div>
                            )*/}
                        </div>
                    </div>
                </AnimatedPage>
            )}
            <Footer />
            {isShow === true ? (
                <>
                    <div className="popup-backdrop">
                        <div className="popup-main">
                            <div className="popup-header">{title}</div>
                            <div className="popup-content">
                                <div className="gameapi-list">
                                    {game != null &&
                                        game.map((item) => (
                                            <div className="gameapi-item" onClick={() => launchGame({ product_type: item.productType, game_code: item.tcgGameCode })}>
                                                <img
                                                    src={`https://images.21947392.com/TCG_GAME_ICONS/${item.productCode}/VI/${item.tcgGameCode}.png`}
                                                    onError={(e) => handleImageError(e, item.productCode, item.tcgGameCode)}
                                                    alt={item.gameName}
                                                />
                                                <div className="gameapi-name">{item.gameName}</div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <button onClick={() => setShow(false)} className="popup-close">
                                Đóng
                            </button>
                        </div>
                    </div>
                </>
            ) : null}
            {playGame && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-close-top" onClick={() => setPlayGame(null)}>
                            <CloseIcon sx={{ fontSize: "26px", color: "#fff" }} />
                        </div>
                        <div className="popup-header">Sảnh {sanh}</div>
                        <div className="popup-content" style={{ textAlign: "center" }}>
                            <div style={{ color: "#000", marginTop: "20px", fontSize: "16px" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                                <div style={{ color: "#000", fontSize: "16px", margin: "10px 0 20px" }}>
                                    Số dư sảnh {sanh}: {balance?.toLocaleString("vi-VN")}
                                </div>
                                <div style={{ display: "flex", color: "#000", justifyContent: "space-around", marginBottom: "10px" }}>
                                    <div>
                                        <input type="radio" name="type_money" value="1" onChange={(e) => setType(1)} />
                                        Chuyển quỹ vào
                                    </div>
                                    <div>
                                        <input type="radio" name="type_money" value="2" onChange={(e) => setType(2)} />
                                        Chuyển quỹ ra
                                    </div>
                                </div>
                                {type == 1 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "14px" }}>
                                        Số tiền nhận được trong game: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) / 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                {type == 2 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "14px" }}>
                                        Số tiền nhận vào ví chính: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) * 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                <div className="relative-input">
                                    <input
                                        className="ipadd"
                                        type="text"
                                        {...register("money", { required: true })}
                                        placeholder="Nhập số tiền"
                                        value={newMoney}
                                        onClick={() => setNewMoney(null)}
                                        onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    />
                                    <div className="absolute-input" onClick={selectAll}>
                                        Tất cả
                                    </div>
                                </div>
                                <button type="submit" className={load === true ? "btn-submit loader" : "btn-submit"}>
                                    Xác nhận
                                </button>
                            </form>
                            <button className="open-game" onClick={openGame}>
                                Vào game ngay
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
            {profile && notify && isShow && notifyParam != null && (
                <>
                    {notify.map(
                        (item) =>
                            item.isShow &&
                            item.title === "popup" && (
                                <div className="popup-backdrop">
                                    <div className="popup-main">
                                        <div className="popup-times" onClick={() => setShow(false)}>
                                            &times;
                                        </div>
                                        <div className="popup-content" style={{ padding: "20px" }}>
                                            {item.content.includes("{") && item.content.includes("}") ? (
                                                <img
                                                    src={item.content.substring(item.content.indexOf("{") + 1, item.content.indexOf("}"))}
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                            ) : (
                                                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                    )}
                </>
            )}

            {yoPopup === true && yoGame && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-close-top" onClick={() => setYoPopup(false)}>
                            <CloseIcon sx={{ fontSize: "26px", color: "#fff" }} />
                        </div>
                        <div className="popup-header">YoGame</div>
                        <div className="popup-content">
                            <ul className="tab-navigation tab-game">
                                <li className={yoType === 1 ? "item active" : "item"} onClick={() => setYoType(1)}>
                                    Nạp tiền
                                </li>
                                <li className={yoType === 2 ? "item active" : "item"} onClick={() => setYoType(2)}>
                                    Rút tiền
                                </li>
                            </ul>
                            <div className="form-sibo">
                                <input
                                    type="text"
                                    value={yoMoney}
                                    onClick={() => setYoMoney(null)}
                                    onChange={(e) => setYoMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    placeholder="Nhập số tiền"
                                />
                                <button onClick={submitYoGame}>Xác nhận</button>
                            </div>
                            <div style={{ textAlign: "center", margin: "10px 0 0" }}>
                                Số dư YoGame: <b>{yoBalance?.toLocaleString("vi-VN")}</b>
                            </div>
                            <div style={{ textAlign: "center", margin: "10px 0 0" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <button className="open-game" onClick={() => (window.location.href = `https://game.ae8888.live?token=${yoGame?.Token}`)}>
                                Vào game
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
export default Home;
